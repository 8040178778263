import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        !isAuthenticated && (
            <div class="centered" >
                <Button onClick={() => loginWithRedirect()}>
                    Log In
                </Button>
            </div>    
            )
    )
}

export default LoginButton