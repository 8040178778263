import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';


const Profile = () => {
    const { user, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && (
            <div class="d-flex flex-row flex-column">
                <div class="p-2">
                    <img class="rounded" src={user.picture} alt={user.name} />
                </div>
                <div class="p-2">
                    <h2>{user.name}</h2>
                </div>
                <div class="p-2">
                    <p>{user.email}</p>
                </div>
            </div>  
        )
    )
}

export default Profile