import React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    return (
        isAuthenticated && ( 
            <div>       
                <Button onClick={() => logout()}>
                    Log Out
                </Button>
                <br />
                <br />
            </div>
            )
    )
}

export default LogoutButton