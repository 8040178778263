import './App.css';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import Profile from './components/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import Chart from './components/Chart';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const { isLoading } = useAuth0(); 
  
      if (isLoading) return <div>Loading ... </div>

      return (
        <>
        <Profile />
        <LoginButton />
        <LogoutButton />
        <Chart />
        </>
      );
}

export default App;
