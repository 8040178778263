import React from "react";
import { Line } from "react-chartjs-2";
import { useAuth0 } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card'



const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Sales",
        data: [33, 40, 55, 80, 85, 100],
        fill: true,
        backgroundColor: "rgba(16,156,119,0.3)",
        borderColor: "rgba(16,156,119,0.5)"
      },
      {
        label: "Redeemed Co-op",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };
  
  export default function MyChart() {
    const { user, isAuthenticated } = useAuth0();
        return (
            isAuthenticated && (
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div className="myChart">
                            <Card>
                                <Line data={data} />
                            </Card>
                        </div>
                    </div>
                </div>
            )
        );
  }

